body {
  position: relative;
  height: 100%;
  background-color: #000;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  font-weight: 500;
  font-family: 'Prompt', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  display: block;
  max-width: 100%;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
input,
textarea {
  color: #fff;
  font-family: 'Prompt', sans-serif;
  border-radius: 0;
}
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  font-family: 'Prompt', sans-serif;
  font-size: 16px;
  line-height: 28px;
}
td {
  margin: 0px;
  padding: 0px;
}
ul {
  list-style: none;
}
form {
  padding: 0px;
  margin: 0px;
}
a {
  color: #3fe3c0;
  transition: all 0.3s ease-out;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a,
span,
div,
button {
  outline: none !important;
}
input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after,
.row:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.loaded .main-wrapper {
  opacity: 0;
}
.icon-load {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: all 0.3s ease-out;
}
.loaded .icon-load {
  transform: translate3d(0, 0%, 0);
}
.sk-folding-cube {
  width: 40px;
  height: 40px;
  transform: rotateZ(45deg);
}
.sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  background: #fff;
  transform: scale(0.96);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #73a6f3;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(0.97) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(0.97) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(0.97) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}
input::placeholder,
textarea::placeholder {
  transition: opacity 0.2s ease-out;
}
input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}
.main-ttl {
  text-align: center;
  color: #3fe3c0;
  background: -webkit-linear-gradient(#3fe3c0, #73a6f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-size: 94px;
  line-height: 80px;
  font-family: 'Bebas', sans-serif;
}
@-moz-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-o-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@font-face {
  font-family: "Bebas";
  src: url("../fonts/Bebas/BebasNeue-Regular.eot");
  src: local("BebasNeue-Regular"), url("../fonts/Bebas/BebasNeue-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/Bebas/BebasNeue-Regular.woff2") format('woff2'), url("../fonts/Bebas/BebasNeue-Regular.woff") format('woff'), url("../fonts/Bebas/BebasNeue-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Prompt";
  src: url("../fonts/Prompt/Prompt-Regular.eot");
  src: local("Prompt-Regular"), url("../fonts/Prompt/Prompt-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/Prompt/Prompt-Regular.woff2") format('woff2'), url("../fonts/Prompt/Prompt-Regular.woff") format('woff'), url("../fonts/Prompt/Prompt-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.header {
  position: fixed;
  top: 40px;
  width: 100%;
  z-index: 3;
  transition: all 0.5s;
}
.header.fixed_header {
  padding: 10px 0;
  top: 0;
  background-color: #000;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);
}
.header .wrapper {
  max-width: 1280px;
}
.header__logo {
  display: block;
  width: 142px;
}
.header__menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
.header__menu li {
  margin-right: 42px;
}
.header__menu li:last-child {
  margin: 0;
}
.header__menu a {
  color: #708db6;
  font-size: 14px;
  display: inline-block;
}
.header__menu a:hover {
  color: #fff;
}
.main-wrapper {
  padding: 0 0 0 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.wrapper {
  min-width: 320px;
  max-width: 1200px;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}
.sect1 {
  padding-top: 170px;
  background-image: url("../img/sect1.jpg");
  background-repeat: repeat-x;
  background-position: center top;
  text-align: center;
  min-height: 670px;
}
.sect1__big-ttl {
  font-family: 'Bebas', sans-serif;
  font-size: 144px;
  line-height: 124px;
  color: #fff;
  text-transform: uppercase;
}
.sect1__big-ttl2 {
  font-family: 'Bebas', sans-serif;
  font-size: 114px;
  line-height: 94px;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #3fe3c0;
  background: -webkit-linear-gradient(#3fe3c0, #73a6f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sect1__descr {
  max-width: 750px;
  width: 100%;
  text-align: center;
  margin: 0 auto 43px;
  font-size: 18px;
  line-height: 28px;
  color: #73a6f3;
}
.sect1__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  flex-wrap: wrap;
}
.sect1__btn {
  padding: 0 30px;
  height: 62px;
  color: #fff;
  font-size: 22px;
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Bebas', sans-serif;
  background-color: #542faa;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 20px;
}
.sect1__btn:hover {
  background-color: #fff;
  color: #000;
}
.sect2 {
  padding-top: 117px;
  background-image: url("../img/sect2.jpg");
  background-repeat: repeat-x;
  background-position: center top;
}
.sect2__ttl {
  text-align: center;
}
.sect2__text {
  margin-bottom: 160px;
}
.sect2__text p {
  margin-bottom: 20px;
}
.sect2__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sect2__col {
  width: calc(50% - 40px);
}
.sect2__col p {
  margin-bottom: 20px;
}
.sect3 {
  background-image: url("../img/sect3.jpg");
  background-repeat: repeat-x;
  background-position: center top;
  padding-top: 337px;
  margin-top: -197px;
  margin-bottom: 160px;
}
.sect3__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sect3__col {
  width: calc(50% - 40px);
}
.sect3__col p {
  margin-bottom: 20px;
}
.sect3__ttl2 {
  font-family: 'Bebas', sans-serif;
  max-width: 315px;
  text-transform: uppercase;
  font-size: 38px;
  padding-top: 10px;
  line-height: 1;
  background: -webkit-linear-gradient(#3fe3c0, #73a6f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sect4 {
  background-image: url("../img/sect4.jpg");
  background-repeat: repeat-x;
  background-position: center top;
  padding-top: 120px;
  margin-top: -120px;
}
.sect4__ttl {
  margin-bottom: 60px;
}
.sect4__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px;
}
.sect4__col {
  width: calc(33.33% - 38px);
  margin: 0 19px 38px;
  border-radius: 30px;
  background-color: #261846;
  padding: 35px 30px;
  text-align: center;
}
.sect4__t {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  line-height: 32px;
  font-family: 'Bebas', sans-serif;
}
.sect4__d {
  font-size: 13px;
  line-height: 20px;
  color: #73a6f3;
}
.sect5 {
  background-image: url("../img/sect5.jpg");
  background-repeat: repeat-x;
  background-position: center top;
  margin-bottom: 160px;
  margin-top: -260px;
  padding-top: 410px;
}
.sect6 {
  background-image: url("../img/sect6.jpg");
  background-repeat: repeat-x;
  background-position: center top -60px;
  padding-bottom: 80px;
}
.sect6__t2 {
  max-width: 680px;
  margin: 0 auto 60px;
  text-align: center;
  color: #73a6f3;
}
.sect6__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sect6__col {
  width: calc(50% - 20px);
  background-color: #261846;
  border-radius: 30px;
  padding: 55px 60px;
}
.sect6__col-t {
  margin-bottom: 25px;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  line-height: 32px;
  font-family: 'Bebas', sans-serif;
}
.sect6__col ul li {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 20px;
  color: #73a6f3;
  padding-left: 21px;
  position: relative;
}
.sect6__col ul li:last-child {
  margin-bottom: 0;
}
.sect6__col ul li:before {
  content: '—';
  position: absolute;
  left: 0;
}
.sect7 {
  background-image: url("../img/sect7.jpg");
  background-repeat: repeat-x;
  background-position: center top 60px;
}
.sect7__ttl {
  margin-bottom: 20px;
  font-size: 58px;
  line-height: 1;
}
.sect7__descr {
  color: #73a6f3;
  margin-bottom: 60px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}
.sect7__row {
  display: flex;
  justify-content: space-between;
}
.sect7__col {
  width: calc(33.33% - 65px);
  text-align: center;
  margin-bottom: 60px;
}
.sect7__t {
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 32px;
  font-family: 'Bebas', sans-serif;
}
.sect7__d {
  font-size: 13px;
  line-height: 20px;
  color: #73a6f3;
}
.sect7__row2 {
  background-color: #261846;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0 40px;
}
.sect7__col2 {
  width: 25%;
  text-align: center;
  padding: 0 15px;
}
.sect7__col2 .ttl {
  color: #73a6f3;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}
.sect7__col2 .item {
  font-size: 30px;
  line-height: 42px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Bebas', sans-serif;
}
.sect8 {
  background-image: url("../img/sect8.jpg");
  background-repeat: repeat-x;
  background-position: center top -70px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.sect8 .main-ttl {
  font-size: 58px;
  line-height: 1;
}
.sect8__row {
  display: flex;
  justify-content: space-between;
}
.sect8__col {
  background: #261846;
  border-radius: 30px;
  padding: 35px 40px 40px;
  width: calc(33.33% - 25px);
  text-align: center;
}
.sect8__col .item {
  margin-bottom: 20px;
}
.sect8__col .item:last-child {
  margin-bottom: 0;
}
.sect8__col .item span {
  display: block;
  color: #73a6f3;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}
.sect8__col .item p {
  font-size: 45px;
  font-family: 'Bebas', sans-serif;
  color: #fff;
  line-height: 1;
}
.sect9 {
  background-image: url("../img/sect9.jpg");
  background-repeat: repeat-x;
  background-position: center top -100px;
  padding-bottom: 300px;
  padding-bottom: 160px;
}
.sect9 .table {
  border-radius: 30px;
  background-color: #261846;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.sect9 .table .col {
  width: 16.67%;
}
.sect9 .table .col:last-child .th,
.sect9 .table .col:last-child .td {
  border-right: none;
}
.sect9 .table .td {
  padding: 20px 10px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Bebas', sans-serif;
  font-size: 20px;
  line-height: 1;
  height: 60px;
  border-bottom: 1px solid rgba(115,166,243,0.2);
  border-right: 1px solid rgba(115,166,243,0.2);
}
.sect9 .table .td:last-of-type {
  height: 80px;
  border-bottom: none;
}
.sect9 .table .td.full {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sect9 .table .td.half-top,
.sect9 .table .td.half-bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sect9 .table .td.half-top {
  height: 120px;
}
.sect9 .table .td.half-bot {
  height: 80px;
}
.sect9 .table .th {
  height: 76px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #73a6f3;
  font-size: 13px;
  line-height: 20px;
  border-bottom: 1px solid rgba(115,166,243,0.2);
  border-right: 1px solid rgba(115,166,243,0.2);
}
.sect10 {
  background-image: url("../img/sect11.jpg"), url("../img/sect10.jpg");
  background-repeat: repeat-x;
  background-position: center bottom -160px, center top 250px;
  margin-bottom: 160px;
}
.sect10__descr {
  font-size: 18px;
  line-height: 28px;
  color: #73a6f3;
  text-align: center;
  margin-bottom: 50px;
}
.sect10__img {
  margin-bottom: 36px;
}
.sect10__text p {
  margin-bottom: 20px;
}
.sect10__text p.blue {
  color: #73a6f3;
  margin: 30px 0 40px;
}
.sect10__btns {
  display: flex;
  align-items: center;
}
.sect10__btns .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 62px;
  border-radius: 20px;
  background-color: #1788fb;
  margin-right: 20px;
  transition: all 0.3s;
}
.sect10__btns .btn:hover {
  opacity: 0.8;
}
.sect10__btns .btn img {
  margin-right: 10px;
}
.sect10__btns .btn span {
  text-transform: uppercase;
  font-family: 'Bebas', sans-serif;
  font-size: 22px;
  color: #fff;
}
.sect11 {
  background-image: url("../img/sect12.jpg");
  background-repeat: repeat-x;
  background-position: center top 150px;
  padding-bottom: 160px;
}
.sect11 p {
  margin-bottom: 40px;
}
.sect11 .blue {
  color: #73a6f3;
}
.sect11__row {
  display: flex;
  justify-content: space-between;
}
.sect11__col {
  width: calc(50% - 20px);
  background-color: #261846;
  border-radius: 30px;
  padding: 40px;
}
.sect11__col li {
  margin-bottom: 20px;
  padding-left: 21px;
  position: relative;
}
.sect11__col li:before {
  content: '—';
  position: absolute;
  left: 0;
}
.sect11__col li:last-child {
  margin-bottom: 0;
}
.sect12__wrapper {
  padding: 60px;
  border-radius: 30px;
  box-shadow: 0px 50px 60px rgba(0,0,0,0.5);
  background-image: url("../img/banner-bg.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
}
.sect12__ttl {
  color: #fff;
  font-size: 90px;
  line-height: 80px;
  background: none;
  margin-bottom: 14px;
  -webkit-text-fill-color: #fff;
}
.sect12 p {
  margin-bottom: 30px;
  width: 540px;
  max-width: 100%;
  margin: 0 auto 30px;
  color: #73a6f3;
  font-size: 13px;
  line-height: 20px;
}
.sect12__btn {
  padding: 0 38px;
  display: inline-flex;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #542faa;
  color: #fff;
  height: 62px;
  font-size: 22px;
  font-family: 'Bebas', sans-serif;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.sect12__btn:hover {
  opacity: 0.8;
}
.sect13 {
  background-image: url("../img/footer_back.jpg");
  background-repeat: repeat-x;
  background-position: center top;
  padding-bottom: 365px;
  margin-top: -220px;
  padding-top: 380px;
}
.sect13__ttl {
  margin-bottom: 60px;
}
.sect13__row {
  width: 765px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.sect13__item {
  width: 255px;
  max-width: 100%;
  margin-bottom: 28px;
}
.sect13__item p {
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 20px;
  color: #73a6f3;
}
.sect13__item a {
  display: inline-flex;
  align-items: center;
  font-family: 'Bebas', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
}
.sect13__item a:hover {
  opacity: 0.7;
}
.sect13__item a img {
  margin-right: 10px;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 145px;
  left: 0;
}
@media (min-width: 3000px) {
  body {
    zoom: 2;
  }
}
@media (max-width: 1100px) {
  .header__menu {
    position: static;
    transform: none;
  }
  .header .wrapper,
  .footer .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sect1__big-ttl {
    font-size: 130px;
  }
  .sect1__big-ttl2 {
    font-size: 94px;
  }
}
@media (max-width: 900px) {
  .sect4__col {
    width: calc(50% - 38px);
  }
  .header__menu li {
    margin-right: 20px;
  }
  .sect1 {
    padding-top: 200px;
  }
  .sect1__big-ttl2 {
    font-size: 82px;
    line-height: 1;
  }
  .sect1 {
    min-height: 100px;
  }
  .sect6__col {
    padding: 35px;
  }
  .sect7__col {
    width: calc(33.33% - 15px);
  }
  .sect10__ttl.main-ttl {
    font-size: 70px;
  }
  .sect11__col {
    padding: 30px;
  }
}
@media (max-width: 768px) {
  .header .wrapper,
  .footer .wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .header__logo {
    margin-bottom: 20px;
  }
  .header__menu a {
    font-size: 12px;
  }
  .header__menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .header__menu li {
    margin-right: 0;
  }
  .sect1__big-ttl {
    font-size: 90px;
    line-height: 1;
  }
  .sect1__big-ttl2 {
    font-size: 50px;
  }
  .main-ttl {
    font-size: 66px;
    line-height: 1;
  }
  .sect2__text {
    margin-bottom: 100px;
  }
  .sect2__col,
  .sect3__col,
  .sect4__col,
  .sect6__col,
  .sect7__col,
  .sect11__col {
    width: 100%;
  }
  .sect5 {
    padding-top: 440px;
  }
  .sect6__col,
  .sect11__col {
    margin-bottom: 20px;
  }
  .sect7__col {
    margin-bottom: 40px;
  }
  .sect7__row,
  .sect7__row2,
  .sect8__row,
  .sect11__row {
    flex-wrap: wrap;
  }
  .sect7__col2,
  .sect8__col {
    width: 100%;
    margin-bottom: 30px;
  }
  .sect7__col2:last-child,
  .sect8__col:last-child {
    margin-bottom: 0;
  }
  .sect9 .table .th {
    height: 55px;
    font-size: 9px;
    line-height: 13px;
  }
  .sect9 .table .td {
    font-size: 14px;
    padding: 15px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sect10__ttl.main-ttl {
    font-size: 40px;
  }
  .sect11 {
    padding-bottom: 60px;
  }
  .sect12__wrapper {
    padding: 40px;
  }
  .sect13__item {
    width: 100%;
  }
  .footer {
    bottom: 50px;
  }
  .sect13 {
    padding-bottom: 175px;
  }
  .header__logo {
    transition: all 0.3s;
  }
  .header.fixed_header {
    transition: all 0.1s;
  }
  .header.fixed_header .header__logo {
    max-height: 0;
    overflow: hidden;
    margin: 0;
  }
  .sect3 {
    background-position: center top 210px;
    background-size: 840px;
  }
  .sect5 {
    background-size: 1000px;
    background-position: center top 90px;
  }
  .sect1__btns {
    max-width: 370px;
    margin: auto;
  }
}
@media (max-width: 480px) {
  .header .wrapper {
    padding: 0 10px;
  }
  .header__menu a {
    font-size: 11px;
  }
  .main-ttl {
    font-size: 52px;
  }
  .wrapper {
    padding: 0 20px;
  }
  .sect4__col {
    margin: 0 0 20px;
  }
  .sect4__row {
    margin: 0;
  }
  .sect9 .table .td {
    font-size: 13px;
  }
  .sect9 .table .th {
    font-size: 8px;
  }
  .sect13 {
    padding-top: 290px;
  }
  .sect9 {
    padding-bottom: 120px;
    background-size: 1320px;
  }
  .sect3,
  .sect13 {
    padding-top: 130px;
    margin-top: -70px;
  }
  .sect10 {
    background-position: center bottom -160px, center top 80px;
  }
}
@media (max-width: 370px) {
  .header__menu a {
    font-size: 10px;
  }
  .sect1__big-ttl {
    font-size: 70px;
  }
  .sect1__big-ttl2 {
    font-size: 34px;
  }
  .sect1__descr {
    font-size: 16px;
  }
  .sect2 {
    padding-top: 60px;
  }
  .sect9 .table {
    min-width: 500px;
  }
  .table-wrap {
    overflow: auto;
  }
  p {
    font-size: 14px;
  }
  br {
    display: none;
  }
  .header__logo {
    width: 120px;
  }
}
