m-font-face( $font-family = "Arial", $file-path = "", $local-name = "", $weight, $style = normal) {
	@font-face {
		font-family: $font-family;
		src: url($file-path + '.eot');
		src: local($local-name),
			 url($file-path + '.eot?#iefix') format('embedded-opentype'),
			 url($file-path + '.woff2') format('woff2'),
			 url($file-path + '.woff') format('woff'),
			 url($file-path + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}


m-font-face("Bebas", "../fonts/Bebas/BebasNeue-Regular", "BebasNeue-Regular", 400);

m-font-face("Prompt", "../fonts/Prompt/Prompt-Regular", "Prompt-Regular", 400);
// m-font-face("Prompt", "../fonts/Prompt/Prompt-Bold", "Prompt-Bold", bold);
//m-font-face("Prompt", "../fonts/Prompt/Prompt-Medium", "Prompt-Medium", 500);
//m-font-face("Prompt", "../fonts/Prompt/Prompt-SemiBold", "Prompt-SemiBold", 600);
