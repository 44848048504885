//main styles
.main-wrapper
	padding 0 0 0 0
	min-width $min-width
	width 100%
	position relative
	overflow hidden
	min-height 100%
	display flex
	flex-direction column
	justify-content flex-start	

.wrapper
	min-width $min-width
	max-width $max-width
	padding 0 40px 0 40px
	margin 0 auto
	position relative




.sect1
	padding-top 170px
	background-image url('../img/sect1.jpg')
	background-repeat repeat-x
	background-position center top
	text-align center
	min-height 670px
	&__big-ttl
		font-family $bebas
		font-size 144px
		line-height 124px
		color #fff
		text-transform uppercase
	&__big-ttl2
		font-family $bebas
		font-size 114px
		line-height 94px
		margin-bottom 40px
		text-transform uppercase
		color #3FE3C0
		background -webkit-linear-gradient(#3FE3C0, #73A6F3)
		-webkit-background-clip text
		-webkit-text-fill-color transparent
	&__descr
		max-width 750px
		width 100%
		text-align center
		margin 0 auto 43px
		font-size 18px
		line-height 28px
		color #73A6F3
	&__btns
		display flex
		align-items center
		justify-content center
		padding-top 40px
		flex-wrap wrap
	&__btn
		padding 0 30px
		height 62px
		color #fff
		font-size 22px
		line-height 1
		text-transform uppercase
		font-family $bebas
		background-color #542FAA
		border-radius 20px
		display inline-flex
		align-items center
		justify-content center
		margin 0 10px 20px
		&:hover
			background-color #fff
			color #000


.sect2
	padding-top 117px
	background-image url('../img/sect2.jpg')
	background-repeat repeat-x
	background-position center top
	&__ttl
		text-align center
	&__text
		margin-bottom 160px
		p
			margin-bottom 20px
	&__row
		display flex
		flex-wrap wrap
		justify-content space-between
	&__col
		width calc(50% - 40px)
		p
			margin-bottom 20px


.sect3
	background-image url('../img/sect3.jpg')
	background-repeat repeat-x
	background-position center top
	padding-top 337px
	margin-top -197px
	margin-bottom 160px
	&__row
		display flex
		flex-wrap wrap
		justify-content space-between
	&__col
		width calc(50% - 40px)
		p
			margin-bottom 20px
	&__ttl2
		font-family $bebas
		max-width 315px
		text-transform uppercase
		font-size 38px
		padding-top 10px
		line-height 1
		background -webkit-linear-gradient(#3FE3C0, #73A6F3)
		-webkit-background-clip text
		-webkit-text-fill-color transparent

.sect4
	background-image url('../img/sect4.jpg')
	background-repeat repeat-x
	background-position center top
	padding-top 120px
	margin-top -120px
	&__ttl
		margin-bottom 60px
	&__row
		display flex
		flex-wrap wrap
		margin 0 -40px
	&__col
		width calc(33.33% - 38px)
		margin 0 19px 38px
		border-radius 30px
		background-color #261846
		padding 35px 30px
		text-align center
	&__t
		margin-bottom 20px
		text-transform uppercase
		color #fff
		font-size 30px
		line-height 32px
		font-family $bebas
	&__d
		font-size 13px
		line-height 20px
		color #73A6F3


.sect5
	background-image url('../img/sect5.jpg')
	background-repeat repeat-x
	background-position center top
	margin-bottom 160px
	margin-top -260px
	padding-top 410px


.sect6
	background-image url('../img/sect6.jpg')
	background-repeat repeat-x
	background-position center top -60px
	padding-bottom 80px
	&__t2
		max-width 680px
		margin 0 auto 60px
		text-align center
		color #73A6F3
	&__row
		display flex
		justify-content space-between
		flex-wrap wrap
	&__col
		width calc(50% - 20px)
		background-color #261846
		border-radius 30px
		padding 55px 60px
		&-t
			margin-bottom 25px
			text-transform uppercase
			color #fff
			font-size 30px
			line-height 32px
			font-family $bebas
		ul
			li
				margin-bottom 10px
				font-size 13px
				line-height 20px
				color #73A6F3
				padding-left 21px
				position relative
				&:last-child
					margin-bottom 0
				&:before
					content '—'
					position absolute
					left 0


.sect7
	background-image url('../img/sect7.jpg')
	background-repeat repeat-x
	background-position center top 60px
	&__ttl
		margin-bottom 20px
		font-size 58px
		line-height 1
	&__descr
		color #73A6F3
		margin-bottom 60px
		font-size 16px
		line-height 26px
		text-align center
	&__row
		display flex
		justify-content space-between
	&__col
		width calc(33.33% - 65px)
		text-align center
		margin-bottom 60px
	&__t
		margin-bottom 10px
		color #fff
		text-transform uppercase
		font-size 30px
		line-height 32px
		font-family $bebas
	&__d
		font-size 13px
		line-height 20px
		color #73A6F3
	&__row2
		background-color #261846
		border-radius 30px
		display flex
		justify-content space-between
		align-items center
		padding 35px 0 40px
	&__col2
		width 25%
		text-align center
		padding 0 15px
		.ttl
			color #73A6F3
			font-size 13px
			line-height 20px
			margin-bottom 10px
		.item
			font-size 30px
			line-height 42px
			text-transform uppercase
			color #fff
			font-family $bebas


.sect8
	background-image url('../img/sect8.jpg')
	background-repeat repeat-x
	background-position center top -70px
	padding-top 80px
	padding-bottom 80px
	.main-ttl
		font-size 58px
		line-height 1
	&__row
		display flex
		justify-content space-between
	&__col
		background #261846
		border-radius 30px
		padding 35px 40px 40px
		width calc(33.33% - 25px)
		text-align center
		.item
			margin-bottom 20px
			&:last-child
				margin-bottom 0
			span
				display block
				color #73A6F3
				font-size 13px
				line-height 20px
				margin-bottom 10px
			p
				font-size 45px
				font-family $bebas
				color #fff
				line-height 1



.sect9
	background-image url('../img/sect9.jpg')
	background-repeat repeat-x
	background-position center top -100px
	padding-bottom 300px
	padding-bottom 160px
	.table
		border-radius 30px
		background-color #261846
		display flex
		justify-content space-between
		text-align center
		.col
			width 16.67%
			&:last-child
				.th,
				.td
					border-right none
		.td
			padding 20px 10px
			text-align center
			color #fff
			text-transform uppercase
			font-family $bebas
			font-size 20px
			line-height 1
			height 60px
			border-bottom 1px solid rgba(#73A6F3, .2)
			border-right 1px solid rgba(#73A6F3, .2)
			&:last-of-type
				height 80px
				border-bottom none
			&.full
				height 200px
				display flex
				align-items center
				justify-content center
			&.half-top,
			&.half-bot
				display flex
				align-items center
				justify-content center
			&.half-top
				height 120px
			&.half-bot
				height 80px
		.th
			height 76px
			padding 20px 10px
			display flex
			align-items center
			justify-content center
			color #73A6F3
			font-size 13px
			line-height 20px
			border-bottom 1px solid rgba(#73A6F3, .2)
			border-right 1px solid rgba(#73A6F3, .2)



.sect10
	background-image url('../img/sect11.jpg'), url('../img/sect10.jpg')
	background-repeat repeat-x
	background-position center bottom -160px, center top 250px
	margin-bottom 160px
	&__descr
		font-size 18px
		line-height 28px
		color #73A6F3
		text-align center
		margin-bottom 50px
	&__img
		margin-bottom 36px
	&__text
		p
			margin-bottom 20px
			&.blue
				color $accent
				margin 30px 0 40px
	&__btns
		display flex
		align-items center
		.btn
			display inline-flex
			align-items center
			justify-content center
			padding 0 30px
			height 62px
			border-radius 20px
			background-color #1788FB
			margin-right 20px
			transition all .3s
			&:hover
				opacity 0.8
			img
				margin-right 10px
			span
				text-transform uppercase
				font-family $bebas
				font-size 22px
				color #fff



.sect11
	background-image url('../img/sect12.jpg')
	background-repeat repeat-x
	background-position center top 150px
	padding-bottom 160px
	p
		margin-bottom 40px
	.blue
		color $accent
	&__row
		display flex
		justify-content space-between
	&__col
		width calc(50% - 20px)
		background-color #261846
		border-radius 30px
		padding 40px
		li
			margin-bottom 20px
			padding-left 21px
			position relative
			&:before
				content '—'
				position absolute
				left 0
			&:last-child
				margin-bottom 0


.sect12
	&__wrapper
		padding 60px
		border-radius 30px
		box-shadow 0px 50px 60px rgba(0, 0, 0, 0.5)
		background-image url('../img/banner-bg.jpg')
		background-size cover
		background-position center
		text-align center
	&__ttl
		color #fff
		font-size 90px
		line-height 80px
		background none
		margin-bottom 14px
		-webkit-text-fill-color #fff
	p
		margin-bottom 30px
		width 540px
		max-width 100%
		margin 0 auto 30px
		color $accent
		font-size 13px
		line-height 20px
	&__btn
		padding 0 38px
		display inline-flex
		margin 0 auto
		border-radius 20px
		background-color #542FAA
		color #fff
		height 62px
		font-size 22px
		font-family $bebas
		align-items center
		justify-content center
		transition all .3s
		&:hover
			opacity 0.8


.sect13
	background-image url('../img/footer_back.jpg')
	background-repeat repeat-x
	background-position center top
	padding-bottom 365px
	margin-top -220px
	padding-top 380px
	&__ttl
		margin-bottom 60px
	&__row
		width 765px
		max-width 100%
		display flex
		flex-wrap wrap
		margin auto
	&__item
		width 255px
		max-width 100%
		margin-bottom 28px
		p
			margin-bottom 8px
			font-size 13px
			line-height 20px
			color $accent
		a
			display inline-flex
			align-items center
			font-family $bebas
			text-transform uppercase
			color #fff
			font-size 20px
			&:hover
				opacity 0.7
			img
				margin-right 10px



.footer
	position absolute
	width 100%
	bottom 145px
	left 0