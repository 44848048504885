/*==========  Desktop First  ==========*/

@media (min-width : 3000px)
    body
        zoom 2

@media (max-width : 1100px)
    .header__menu
        position static
        transform none
    .header .wrapper,
    .footer .wrapper
        display flex
        justify-content space-between
        align-items center
    .sect1__big-ttl
        font-size 130px
    .sect1__big-ttl2
        font-size 94px


@media (max-width : 900px)
    .sect4__col
        width calc(50% - 38px)
    .header__menu li
        margin-right 20px
    .sect1
        padding-top 200px
    .sect1__big-ttl2
        font-size 82px
        line-height 1
    .sect1
        min-height 100px
    .sect6__col
        padding 35px
    .sect7__col
        width calc(33.33% - 15px)
    .sect10__ttl.main-ttl
        font-size 70px
    .sect11__col
        padding 30px




@media (max-width : 768px)
    .header .wrapper, .footer .wrapper
        flex-wrap wrap
        justify-content center
    .header__logo
        margin-bottom 20px
    .header__menu a
        font-size 12px
    .header__menu
        display flex
        justify-content space-between
        width 100%
    .header__menu li
        margin-right 0
    .sect1__big-ttl
        font-size 90px
        line-height 1
    .sect1__big-ttl2
        font-size 50px
    .main-ttl
        font-size 66px
        line-height 1
    .sect2__text
        margin-bottom 100px
    .sect2__col,
    .sect3__col,
    .sect4__col,
    .sect6__col,
    .sect7__col,
    .sect11__col
        width 100%
    .sect5
        padding-top 440px
    .sect6__col,
    .sect11__col
        margin-bottom 20px
    .sect7__col
        margin-bottom 40px
    .sect7__row,
    .sect7__row2,
    .sect8__row,
    .sect11__row
        flex-wrap wrap
    .sect7__col2,
    .sect8__col
        width 100%
        margin-bottom 30px
        &:last-child
            margin-bottom 0
    
    .sect9 .table .th
        height 55px
        font-size 9px
        line-height 13px
    .sect9 .table .td
        font-size 14px
        padding 15px 1px
        display flex
        align-items center
        justify-content center
    .sect10__ttl.main-ttl
        font-size 40px
    .sect11
        padding-bottom 60px
    .sect12__wrapper
        padding 40px
    .sect13__item
        width 100%
    .footer
        bottom 50px
    .sect13
        padding-bottom 175px
    .header__logo
        transition all .3s
    .header.fixed_header
        transition all .1s
        .header__logo
            max-height 0
            overflow hidden
            margin 0
    .sect3
        background-position center top 210px
        background-size 840px
    .sect5
        background-size 1000px
        background-position center top 90px
    .sect1__btns
        max-width 370px
        margin auto




@media (max-width : 480px)
    .header .wrapper
        padding 0 10px
    .header__menu a
        font-size 11px
    .main-ttl
        font-size 52px
    .wrapper
        padding 0 20px
    .sect4__col
        margin 0 0 20px
    .sect4__row
        margin 0
    .sect9 .table .td
        font-size 13px
    .sect9 .table .th
        font-size 8px
    .sect13
        padding-top 290px
    .sect9
        padding-bottom 120px
        background-size 1320px
    .sect3,
    .sect13
        padding-top 130px
        margin-top -70px
    .sect10
        background-position center bottom -160px, center top 80px
        



@media (max-width : 370px)
    .header__menu a
        font-size 10px
    .sect1__big-ttl
        font-size 70px
    .sect1__big-ttl2
        font-size 34px
    .sect1__descr
        font-size 16px
    .sect2
        padding-top 60px
    .sect9 .table
        min-width 500px
    .table-wrap
        overflow auto
    p
        font-size 14px
    br
        display none
    .header__logo
        width 120px